import {
  Denied,
  Applied,
  Accepted,
  Enrolled,
  ThumbsUp,
  Deferred,
  Waitlisted,
  ThumbsSide,
  ThumbsDown,
  CollegeCity,
  AddedCollege,
  CollegeSmall,
  CollegeLarge,
  CollegeRural,
  CollegeMedium,
  CollegePublic,
  CollegePrivate,
  UploadedLetter,
  CollegeSuburban,
  CollegeForprofit
} from "./icons";

export const Colors = {
  RED: "RED",
  BLUE: "BLUE",
  GREEN: "GREEN",
  ORANGE: "ORANGE",
  DEFAULT: "DEFAULT"
};

export const Languages = {
  ENGLISH: "ENGLISH",
  SPANISH: "SPANISH"
};

export const LivingChoice = {
  ON_CAMPUS: "ON_CAMPUS",
  OFF_CAMPUS: "OFF_CAMPUS",
  WITH_FAMILY: "WITH_FAMILY"
};

export const Ethnicity = {
  AIAN: "AIAN",
  NHPI: "NHPI",
  ASIAN: "ASIAN",
  BLACK: "BLACK",
  WHITE: "WHITE",
  HISPANIC: "HISPANIC"
};

export const EthnicityGroup = {
  TOTAL: "TOTAL",
  NON_WHITE: "NON_WHITE",
  STUDENT_ETHNICITY: "STUDENT_ETHNICITY"
};

export const AwardStatus = {
  READY: "READY",
  INVALID: "INVALID",
  PENDING: "PENDING",
  MANUAL_REVIEW: "MANUAL_REVIEW",
  SIZE_LIMIT_EXCEEDED: "SIZE_LIMIT_EXCEEDED",
  TIMEOUT: "TIMEOUT"
};

export const SchoolStatus = {
  READY: "READY",
  INVALID: "INVALID",
  NO_AWARD: "NO_AWARD",
  IN_REVIEW: "IN_REVIEW",
  ESTIMATED: "ESTIMATED",
  GRANTS_REVIEWED: "GRANTS_REVIEWED",
  NO_AID: "NO_AID"
};

export const Affordability = {
  AFFORDABLE: "AFFORDABLE",
  NOT_AFFORDABLE: "NOT_AFFORDABLE",
  SOMEWHAT_AFFORDABLE: "SOMEWHAT_AFFORDABLE"
};

export const CollegeType = {
  TWO_YEARS: "TWO_YEARS",
  FOUR_YEARS: "FOUR_YEARS"
};

export const Enrollment = {
  ENROLLMENT_0_DENIED: "ENROLLMENT_0_DENIED",
  ENROLLMENT_1_INTERESTED: "ENROLLMENT_1_INTERESTED",
  ENROLLMENT_2_APPLIED: "ENROLLMENT_2_APPLIED",
  ENROLLMENT_21_DEFERRED: "ENROLLMENT_21_DEFERRED",
  ENROLLMENT_22_WAITLISTED: "ENROLLMENT_22_WAITLISTED",
  ENROLLMENT_3_ACCEPTED: "ENROLLMENT_3_ACCEPTED",
  ENROLLMENT_4_UPLOADED_LETTER: "ENROLLMENT_4_UPLOADED_LETTER",
  ENROLLMENT_5_ENROLLED: "ENROLLMENT_5_ENROLLED"
};

export const EnrollmentDictionary = {
  ENROLLMENT_1_INTERESTED: "Interested",
  ENROLLMENT_2_APPLIED: "Applied",
  ENROLLMENT_0_DENIED: "Denied",
  ENROLLMENT_21_DEFERRED: "Deferred",
  ENROLLMENT_22_WAITLISTED: "Waitlisted",
  ENROLLMENT_3_ACCEPTED: "Got Accepted",
  ENROLLMENT_4_UPLOADED_LETTER: "Uploaded Letter",
  ENROLLMENT_5_ENROLLED: "Enrolled"
};

export const GradeYear = {
  HIGH_SCHOOL_SENIOR: "HIGH_SCHOOL_SENIOR",
  HIGH_SCHOOL_JUNIOR: "HIGH_SCHOOL_JUNIOR",
  HIGH_SCHOOL_SOPHOMORE: "HIGH_SCHOOL_SOPHOMORE",
  OTHER: "OTHER"
};

export const GradeYearTypes = [
  {
    value: "HIGH_SCHOOL_SENIOR",
    label: "High School Senior"
  },
  {
    value: "HIGH_SCHOOL_JUNIOR",
    label: "High School Junior"
  },
  {
    value: "HIGH_SCHOOL_SOPHOMORE",
    label: "High School Sophomore"
  },
  {
    value: "OTHER",
    label: "Other"
  }
];

export function getGradeYearLabel(value) {
  const gradeType = GradeYearTypes.find((item) => item.value === value);
  return gradeType ? gradeType.label : "";
}

export const getTheme = ({ size, strokeColor, affordability }) => {
  switch (affordability) {
    case Affordability.AFFORDABLE:
      return {
        label: "Affordable",
        color: Colors.GREEN,
        icon: (
          <ThumbsUp
            size={size}
            strokeColor={strokeColor ? "stroke-primary-green" : undefined}
          />
        )
      };
    case Affordability.SOMEWHAT_AFFORDABLE:
      return {
        color: Colors.ORANGE,
        label: "Somewhat Affordable",
        icon: (
          <ThumbsSide
            size={size}
            strokeColor={strokeColor ? "stroke-primary-orange" : undefined}
          />
        )
      };
    case Affordability.NOT_AFFORDABLE:
      return {
        color: Colors.RED,
        label: "Not Affordable",
        icon: (
          <ThumbsDown
            size={size}
            strokeColor={strokeColor ? "stroke-primary-red" : undefined}
          />
        )
      };
    default:
      return {
        color: Colors.BLUE,
        label: "Missing Information!",
        icon: <div style={{ height: `${size}px`, width: `${size}px` }} />
      };
  }
};

export const getColors = (color) => {
  switch (color) {
    case Colors.RED:
      return {
        primary: {
          hexa: "#D3332D",
          bg: "bg-primary-red",
          text: "text-primary-red",
          border: "border-primary-red",
          shadow: "shadow-primary-red"
        },
        secondary: {
          hexa: "#F9D9D9",
          bg: "bg-secondary-red",
          text: "text-secondary-red",
          border: "border-secondary-red",
          shadow: "shadow-secondary-red"
        },
        alternative: {
          hexa: "#750808",
          bg: "bg-alternative-red",
          text: "text-alternative-red",
          border: "border-alternative-red",
          shadow: "shadow-alternative-red"
        }
      };
    case Colors.BLUE:
      return {
        primary: {
          hexa: "#3661F5",
          bg: "bg-primary-blue",
          text: "text-primary-blue",
          border: "border-primary-blue",
          shadow: "shadow-primary-blue"
        },
        secondary: {
          hexa: "#CCE5FF",
          bg: "bg-secondary-blue",
          text: "text-secondary-blue",
          border: "border-secondary-blue",
          shadow: "shadow-secondary-blue"
        },
        alternative: {
          hexa: "#1F378A",
          bg: "bg-alternative-blue",
          text: "text-alternative-blue",
          border: "border-alternative-blue",
          shadow: "shadow-alternative-blue"
        }
      };
    case Colors.GREEN:
      return {
        primary: {
          hexa: "#30A375",
          bg: "bg-primary-green",
          text: "text-primary-green",
          border: "border-primary-green",
          shadow: "shadow-primary-green"
        },
        secondary: {
          hexa: "#B0F2D8",
          bg: "bg-secondary-green",
          text: "text-secondary-green",
          border: "border-secondary-green",
          shadow: "shadow-secondary-green"
        },
        alternative: {
          hexa: "#1C6A4B",
          bg: "bg-alternative-green",
          text: "text-alternative-green",
          border: "border-alternative-green",
          shadow: "shadow-alternative-green"
        }
      };
    case Colors.ORANGE:
      return {
        primary: {
          hexa: "#EA6C0B",
          bg: "bg-primary-orange",
          text: "text-primary-orange",
          border: "border-primary-orange",
          shadow: "shadow-primary-orange"
        },
        secondary: {
          hexa: "#FFD8BA",
          bg: "bg-secondary-orange",
          text: "text-secondary-orange",
          border: "border-secondary-orange",
          shadow: "shadow-secondary-orange"
        },
        alternative: {
          hexa: "#95480C",
          bg: "bg-alternative-orange",
          text: "text-alternative-orange",
          border: "border-alternative-orange",
          shadow: "shadow-alternative-orange"
        }
      };

    default:
      const colors = {
        hexa: "#000000",
        bg: "bg-white",
        text: "text-black",
        border: "border-black",
        shadow: "shadow-lg"
      };
      return {
        primary: { ...colors },
        secondary: { ...colors, hexa: "#EAEAEA" },
        alternative: { ...colors, hexa: "#CCCCCC" }
      };
  }
};

export const getCollegeEnvironment = {
  SMALL: (
    <>
      <CollegeSmall size={50} color="stroke-current" />
      <span className="mt-2 text-lg font-bold text-black">Small</span>
    </>
  ),
  MEDIUM: (
    <>
      <CollegeMedium size={50} color="stroke-current" />
      <span className="mt-2 text-lg font-bold text-black">Medium</span>
    </>
  ),
  LARGE: (
    <>
      <CollegeLarge size={50} color="stroke-current" />
      <span className="mt-2 text-lg font-bold text-black">Large</span>
    </>
  ),
  Rural: (
    <>
      <CollegeRural size={50} color="stroke-current" />
      <span className="mt-2 text-lg font-bold text-black">Rural</span>
    </>
  ),
  Suburb: (
    <>
      <CollegeSuburban size={50} color="stroke-current" />
      <span className="mt-2 text-lg font-bold text-black">Suburban</span>
    </>
  ),
  Town: (
    <>
      <CollegeRural size={50} color="stroke-current" />
      <span className="mt-2 text-lg font-bold text-black">Town</span>
    </>
  ),
  City: (
    <>
      <CollegeCity
        size={50}
        iconColor="fill-current"
        strokeColor="stroke-current"
      />
      <span className="mt-2 text-lg font-bold text-black">City</span>
    </>
  ),
  PRIVATE: (
    <>
      <CollegePrivate
        size={50}
        iconColor="fill-current"
        strokeColor="stroke-current"
      />
      <span className="mt-2 text-lg font-bold text-black">Private</span>
    </>
  ),
  PUBLIC: (
    <>
      <CollegePublic
        size={50}
        iconColor="fill-current"
        strokeColor="stroke-current"
      />
      <span className="mt-2 text-lg font-bold text-black">Public</span>
    </>
  ),
  FOR_PROFIT: (
    <>
      <CollegeForprofit
        size={50}
        iconColor="fill-current"
        strokeColor="stroke-current"
      />
      <span className="mt-2 text-lg font-bold text-black">For-Profit</span>
    </>
  )
};

export const getEnrollmentIcon = ({ size, color, enrollment }) => {
  switch (enrollment) {
    case Enrollment.ENROLLMENT_0_DENIED:
      return <Denied size={size} color={color} />;
    case Enrollment.ENROLLMENT_1_INTERESTED:
      return <AddedCollege size={size} color={color} />;
    case Enrollment.ENROLLMENT_2_APPLIED:
      return <Applied size={size} color={color} />;
    case Enrollment.ENROLLMENT_21_DEFERRED:
      return <Deferred size={size} color={color} />;
    case Enrollment.ENROLLMENT_22_WAITLISTED:
      return <Waitlisted size={size} color={color} />;
    case Enrollment.ENROLLMENT_3_ACCEPTED:
      return <Accepted size={size} color={color} />;
    case Enrollment.ENROLLMENT_4_UPLOADED_LETTER:
      return <UploadedLetter size={size} color={color} />;
    case Enrollment.ENROLLMENT_5_ENROLLED:
      return <Enrolled size={size} color={color} />;
    default:
      return null;
  }
};

export const getGrantsAndScholarships = (aidAmounts) => {
  const grants = aidAmounts || [];
  return grants.filter(
    (aid) =>
      aid.category.toLowerCase() === "other" ||
      aid.category.toLowerCase() === "grant" ||
      aid.category.toLowerCase() === "scholarship" ||
      (aid.category.toLowerCase() === "waiver" &&
        aid.subCategory.toLowerCase() !== "insurance_waiver")
  );
};

export const computeWarningText = (graduationRate) => {
  return graduationRate === 0
    ? "Not Available"
    : graduationRate <= 25
    ? "Lower Than Most"
    : graduationRate >= 75
    ? "Higher Than Most"
    : "";
};

export const computeEthnicityGroupText = (ethnicityGroup, ethnicityStudent) => {
  switch (ethnicityGroup) {
    case EthnicityGroup.TOTAL:
      return "all Students";
    case EthnicityGroup.NON_WHITE:
      return "students of color";
    case EthnicityGroup.STUDENT_ETHNICITY:
    default:
      return `${ethnicityDisplay(ethnicityStudent)} students`;
  }
};

export const ethnicityDisplay = (ethnicity) => {
  switch (ethnicity) {
    case Ethnicity.AIAN:
      return "American Indian and Alaska Native";
    case Ethnicity.ASIAN:
      return "Asian";
    case Ethnicity.BLACK:
      return "Black or African American";
    case Ethnicity.HISPANIC:
      return "Hispanic";
    case Ethnicity.NHPI:
      return "Native Hawaiian and Pacific Islander";
    case Ethnicity.WHITE:
      return "White";
    default:
      return "";
  }
};

export const scrollIntoViewIfNeeded = (divRefScroll) => {
  setTimeout(() => {
    if (divRefScroll.current) {
      if (divRefScroll.current.scrollIntoViewIfNeeded)
        divRefScroll.current.scrollIntoViewIfNeeded(true);
      else
        divRefScroll.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "end"
        });
    }
  }, 1);
};

export const decidedRoute = (path) => {
  const url = `${window.SERVER_DATA.REACT_APP_DECIDED_URL}${path}`;

  const appRoute = encodeURIComponent(window.location.href);
  return `${url}?returnTo=${appRoute}`;
};

export const decidedRouteSupport = (path) => {
  const url = window.SERVER_DATA.REACT_APP_DECIDED_SUPPORT_URL;
  const appRoute = encodeURIComponent(window.location.href);
  return `${url}${path ? path : ""}?returnTo=${appRoute}`;
};

export const getRedirectUri = () => {
  const redirectUri = `${window.location.origin}/authenticate`;
  const nextParam = `?next=${
    new URLSearchParams(window.location.search).get("next") ||
    encodeURIComponent(
      window.location.pathname + window.location.search + window.location.hash
    )
  }`;

  return `${redirectUri}${nextParam}`;
};

export const getResultUrl = () => {
  const nextParam =
    new URLSearchParams(window.location.search).get("next") || "";

  return `${window.location.origin}${nextParam}`;
};

export function retryTimeout(callback, options) {
  function retry(callback, options = {}) {
    const limit = options.limit === undefined ? 10 : options.limit;
    const retryOn = options.retryOn || ((err) => true);

    let counter = 0;

    return async function withRetry(...args) {
      try {
        return await callback(...args);
      } catch (err) {
        if (retryOn(err)) {
          counter++;

          if (limit !== null && counter > limit) {
            throw err;
          }

          return await withRetry(...args);
        } else {
          throw err;
        }
      }
    };
  }

  return retry(callback, {
    ...options,
    retryOn: (err) => {
      const retry =
        err.message === "GraphQL error: Execution timed out." ||
        err.code === "UnexpectedLambdaException";
      return retry;
    }
  });
}
